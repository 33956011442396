<template>
  <div class="wrapper">
    <el-steps
      :active="currentStep"
      align-center
      finish-status="success"
      style="margin: 10px 0"
    >
      <el-step title="填写活动基本信息"></el-step>
      <el-step title="选择活动KOL"></el-step>
      <el-step title="填写合作供应商"></el-step>
    </el-steps>
    <step1
      :isSetDateFormat="isSetDateFormat"
      v-show="currentStep === 0"
      @nextStep="step1NextStep"
    />
    <step2
      v-show="currentStep === 1"
      @prevStep="rebackToStep1"
      @nextStep="step2NextStep"
    />
    <step3
      v-show="currentStep === 2"
      @prevStep="currentStep = 1"
      @submitCreatCampaign="submitCreatCampaign"
    />
  </div>
</template>

<script>
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import { creatCampaign } from "@/api/campaign";
import { mapMutations } from "vuex";

export default {
  name: "createCampaign",
  data() {
    return {
      currentStep: 0,
      step1Params: {},
      step2Params: {},
      isSetDateFormat: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.setIsAddClass(false);
    next();
  },
  methods: {
    ...mapMutations({
      setIsAddClass: "SET_ISADDCLASS",
    }),
    step1NextStep(params) {
      this.step1Params = params;
      this.currentStep = 1;
    },
    step2NextStep(params) {
      this.step2Params = params;
      this.currentStep = 2;
    },
    rebackToStep1() {
      this.currentStep = 0;
      this.isSetDateFormat = true;
      setTimeout(() => {
        this.isSetDateFormat = false;
      }, 100);
    },
    // 创建活动
    async submitCreatCampaign(params) {
      let campaignInfo = {
        ...params,
        ...this.step1Params,
        ...this.step2Params,
      };
      let res = await creatCampaign(campaignInfo);
      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "创建活动成功!",
        });
        this.$router.push({ name: "CampaignManage" });
      } else if (res.code === 201) {
        console.log("非法请求参数");
      } else if (res.code === 202) {
        console.log("已存在活动名称");
      } else if (res.code === 203) {
        this.$message({
          type: "error",
          message: "创建活动失败，请联系管理员！",
        });
      } else if (res.code === 204) {
        this.$message({
          type: "error",
          message: "删除活动失败，请联系管理员！",
        });
      } else if (res.code === 205) {
        console.log("更新活动中标供应商id失败");
      } else if (res.code === 206) {
        console.log("修改活动KOL信息失败");
      } else if (res.code === 207) {
        this.$message({
          type: "info",
          message: "活动kol列表不能为空",
        });
      } else {
        this.$message({
          type: "error",
          message: "创建活动失败,请联系管理员",
        });
      }
    },
  },
  components: {
    Step1,
    Step2,
    Step3,
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  background-color: #fff;
  padding: 10px 5px;

  .newLineDiv {
    height: 60px;
    border: 1px solid #eee;
    border-top: none;
    margin-bottom: 20px;
    position: relative;

    .el-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
