<template>
  <el-form
    class="step1Class"
    ref="baseInfoRuleForm"
    :rules="baseInfoRules"
    label-width="100px"
    :model="createForm"
  >
    <el-row>
      <el-col :offset="8" :span="8">
        <el-form-item label="活动品牌" prop="brand">
          <el-select v-model="createForm.brand" placeholder="请选择">
            <el-option
              v-for="item in brandList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="8">
        <el-form-item label="活动名称" prop="campaignName">
          <el-input v-model="createForm.campaignName"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="10">
        <el-form-item label="活动时间" prop="duration">
          <el-date-picker
            v-model="createForm.duration"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="YYYY-MM-DD"
            :shortcuts="shortcuts"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="10">
        <el-form-item label="品线" prop="brandLine">
          <el-select
            filterable
            allow-create
            default-first-option
            v-model="createForm.brandLine"
            placeholder="请选择品线"
          >
            <el-option
              v-for="item in brandLineList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="10">
        <el-form-item label="产品" prop="product">
          <el-select
            filterable
            allow-create
            default-first-option
            v-model="createForm.product"
            placeholder="请选择产品"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in productList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="10">
        <el-form-item label="活动类型" prop="campaignType">
          <el-select
            filterable
            allow-create
            default-first-option
            v-model="createForm.campaignType"
            placeholder="请选择活动类型"
          >
            <el-option
              v-for="item in campaignTypeList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="10">
        <el-form-item label="活动说明" prop="campaignDesc">
          <el-input
            style="width: 361px"
            v-model="createForm.campaignDesc"
            type="textarea"
            rows="3"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="8">
        <el-form-item label="活动文档" prop="campaignFile">
          <el-upload
            ref="campaignUpload"
            :action="campaignAction"
            :headers="{ token }"
            :before-upload="fileBeforeUpload"
            :on-success="campaignFileOnSuccess"
            :on-error="campaignFileOnError"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <template #tip>
              <p
                style="
                  font-size: 12px;
                  color: red;
                  line-height: 20px;
                  opacity: 0.8;
                "
              >
                * 不超过30M
              </p>
            </template>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="8">
        <el-form-item label="活动预算" prop="cost">
          <el-input v-model="createForm.cost" placeholder="请输入活动预算">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="8" :span="8">
        <el-form-item>
          <el-button @click="cancelCreatCampaign" style="margin-right: 80px"
            >取消
          </el-button>
          <el-button
            type="primary"
            @click="nextStepToAddKol('baseInfoRuleForm')"
            >下一步
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCampaignBrand,
  isCampaignNameExit,
  getCampaignType,
  getCampaignBrandLine,
  getCampaignProduct,
} from "@/api/campaign";

export default {
  name: "step1",
  data() {
    let validateCost = (rule, value, callback) => {
      if (!Number.isInteger(value - 0)) {
        callback(new Error("请输入数字值"));
      } else {
        callback();
      }
    };
    let validateCampaignName = async (rule, value, callback) => {
      let res = await isCampaignNameExit({ campaignName: value });
      if (res.code === 202) {
        callback(new Error("活动名已经存在"));
      } else {
        callback();
      }
    };
    return {
      baseInfoRules: {
        brand: [{ required: true, message: "请选择品牌", trigger: "change" }],
        campaignName: [
          { required: true, message: "请填写活动名称", trigger: "blur" },
          { validator: validateCampaignName, trigger: "blur" },
        ],
        duration: [
          { required: true, message: "请选择活动时间", trigger: "change" },
        ],
        cost: [{ validator: validateCost, trigger: "blur" }],
      },
      createForm: {
        brand: "",
        campaignName: "",
        campaignType: "",
        duration: "",
        campaignDesc: "",
        cost: null,
        brandLine: "",
        product: [],
      },
      brandList: [],
      shortcuts: [
        {
          text: "最近10天",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      // 活动文件上传数据
      campaignAction: process.env.VUE_APP_API_HOST + "/campaign/file",
      campaignFilePath: "",
      campaignTypeList: [],
      brandLineList: [],
      productList: [],
    };
  },
  props: {
    isSetDateFormat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    this.campaignTypeList = this.$storage.getItem("campaignTypeList") || [];
    this.getBrandList();
    // this.getCampaignTypeList();
    this.getCampaignBrandLineFunc();
    this.getCampaignProductFunc();
  },
  methods: {
    async getCampaignBrandLineFunc() {
      let { code, data } = await getCampaignBrandLine();
      if (code === 0) {
        data.forEach((v) => {
          this.brandLineList.push({
            value: v,
            label: v,
          });
        });
      }
      this.$storage.setItem("brandLineList", this.brandLineList);
    },
    async getCampaignProductFunc() {
      let { code, data } = await getCampaignProduct();
      if (code === 0) {
        data.forEach((v) => {
          this.productList.push({
            value: v,
            label: v,
          });
        });
      }
      this.$storage.setItem("productList", this.productList);
    },

    // async getCampaignTypeList() {
    //   let res = await getCampaignType();
    //   // if (res.code === 0) {
    //   //   this.campaignTypeList = res.data;
    //   // }
    // },
    async getBrandList() {
      let { code, data } = await getCampaignBrand();
      if (code === 0) {
        const formatData = data.map((v) => ({
          label: v.brandName,
          value: v.brandName,
          id: v.id,
        }));
        this.$storage.setItem("brandList", formatData);
        this.brandList = formatData;
      }
    },
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 30;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于30M",
          type: "warning",
        });
        return false;
      } else if (file.size < 0) {
        this.$message({
          message: "文件不能为空！",
          type: "warning",
        });
      } else {
        return true;
      }
    },
    campaignFileOnSuccess(res) {
      if (res.code === 0) {
        this.campaignFilePath = res.data;
      }
    },
    campaignFileOnError() {
      this.$refs.campaignUpload.clearFiles();
      this.$message.error("上传文件失败!");
    },
    cancelCreatCampaign() {
      this.$router.push({ name: "CampaignManage" });
    },
    nextStepToAddKol(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formatForm();
          this.$emit("nextStep", {
            ...this.createForm,
            docDir: this.campaignFilePath,
          });
        } else {
          return false;
        }
      });
    },
    formatForm() {
      this.createForm.cost =
        this.createForm.cost === null ? null : (this.createForm.cost - 0) * 100;
      if (Array.isArray(this.createForm.duration)) {
        this.createForm.duration = this.createForm.duration.join(",");
      }
    },
  },
  watch: {
    isSetDateFormat(val) {
      if (val) {
        this.createForm.duration = this.createForm.duration.split(",");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
<style lang="scss"></style>
