<template>
  <div>
    <div style="display: flex; align-items: center">
      供应商：
      <el-select
        v-model="agencies"
        placeholder="请选择"
        multiple
        collapse-tags
        style="width: 260px"
      >
        <el-option
          v-for="item in agencyList"
          :key="item.id"
          :label="item.agencyName"
          :value="item.agencyName"
        >
        </el-option>
        <div class="controlBrand" v-if="isShowAgency">
          <span @click="controlBrand">供应商管理</span>
        </div>
      </el-select>
      <!-- <el-checkbox
        v-model="callAgency"
        style="margin-left: 60px"
        :true-label="1"
        :false-label="0"
        >是否通知供应商
      </el-checkbox> -->
    </div>
    <div style="text-align: center">
      <el-button @click="goBack">继续添加KOL</el-button>
      <el-button type="primary" @click="submitCreatCampaign"
        >提交创建活动
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAgency } from "@/api/campaign";

export default {
  name: "step3",
  data() {
    return {
      agencies: [],
      agencyList: [],
      createForm: {},
      // callAgency: 1,
    };
  },
  mounted() {
    this.getAgencyList();
    //  供应商自己登录会把自己的账号默认显示出来
    // this.agencies.push(this.$storage.getItem("agencyName"));
  },
  computed: {
    ...mapGetters(["menuList"]),
    // 是否有agency权限
    isShowAgency() {
      const res = this.menuList.find((v) => v.id === 410);
      return res
        ? res.children.find((v) => v.id === 460)
          ? true
          : false
        : false;
    },
  },
  methods: {
    async getAgencyList() {
      let { data } = await getAgency();
      this.agencyList = data;
    },
    controlBrand() {
      this.$router.push({ name: "Agency" });
    },
    goBack() {
      this.$emit("prevStep");
    },
    submitCreatCampaign() {
      const agency = [];
      this.agencies.forEach((v) => {
        this.agencyList.forEach((k) => {
          if (v === k.agencyName) {
            agency.push({
              id: k.id,
              name: k.agencyName,
            });
          }
        });
      });
      this.$emit("submitCreatCampaign", {
        agency: JSON.stringify(agency),
        createType: this.callAgency,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.controlBrand {
  height: 35px;
  line-height: 40px;
  font-size: 14px;
  color: #606266;
  text-align: right;
  padding-right: 20px;
  border-top: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    color: #2e6ea5;
  }
}
</style>
