<template>
  <div style="background-color: #fff; padding: 10px 2px 10px 0">
    <!--侧边展示已选账号-->
    <div v-show="isShowBtn" @click="showSideBoard" class="haveKOL">
      <p style="text-align: center">
        {{ additional ? "追加账号" : "活动添加账号" }}
        <span style="display: inline-block;">
          ({{ activityKolNumber }})
        </span>
      </p>
    </div>
    <!-- 侧边弹出框 -->
    <el-drawer
      title="活动已选KOL"
      v-model="isShowBoard"
      direction="rtl"
      size="20%"
      :show-close="false"
    >
      <div class="drawer_content">
        <el-radio-group
          v-model="drawerPlatform"
          class="svgWrap"
          fill="transparent"
        >
          <el-radio-button label="all">
            <svg-icon
              class-name="all"
              class="svgPlatform"
              :class="drawerPlatform === 'all' ? 'all' : ''"
            />
          </el-radio-button>
          <el-radio-button label="wechat">
            <svg-icon
              class-name="wechat"
              class="svgPlatform"
              :class="drawerPlatform === 'wechat' ? 'wechat' : ''"
            />
          </el-radio-button>
          <el-radio-button label="weibo">
            <svg-icon
              class-name="weibo"
              class="svgPlatform"
              :class="drawerPlatform === 'weibo' ? 'weibo' : ''"
            />
          </el-radio-button>
          <el-radio-button label="douyin">
            <svg-icon
              class-name="douyin"
              class="svgPlatform"
              :class="drawerPlatform === 'douyin' ? 'douyin' : ''"
            />
          </el-radio-button>
          <el-radio-button label="xiaohongshu">
            <svg-icon
              class-name="xiaohongshu"
              class="svgPlatform"
              :class="drawerPlatform === 'xiaohongshu' ? 'xiaohongshu' : ''"
            />
          </el-radio-button>
          <el-radio-button label="bilibili">
            <svg-icon
              class-name="bilibili"
              :class="drawerPlatform === 'bilibili' ? 'bilibili' : ''"
            />
          </el-radio-button>
          <el-radio-button label="kuaishou">
            <svg-icon
              class-name="kuaishou"
              class="svgPlatform"
              :class="drawerPlatform === 'kuaishou' ? 'kuaishou' : ''"
            />
          </el-radio-button>
        </el-radio-group>
        <div class="drawer_main">
          <template v-if="showActivityKolList.length !== 0">
            <div
              class="mainBox"
              v-for="(item, i) in showActivityKolList"
              :key="i"
            >
              <img class="left" :src="item.logoUrl" alt="" />
              <div class="right">
                <p class="kolName">名称: {{ item.kolName }}</p>
                <p style="height: 26px; line-height: 26px">
                  平台:
                  <svg-icon
                    :class-name="`img${item.site}`"
                    :class="['imgIcon', item.site]"
                  />
                </p>
              </div>
              <svg-icon
                class-name="iconshanchu"
                @click="delKolFromActivityList(item)"
                class="del"
              />
            </div>
          </template>
          <p v-else class="empty">活动中KOL为空</p>
        </div>
        <div class="drawer_footer">
          <div>
            <p>总： {{ showActivityKolList.length }}</p>
            <el-button type="text" @click="clearAll">全部清空</el-button>
          </div>
          <el-button size="mini" type="primary" @click="submitKol"
            >提交
          </el-button>
        </div>
      </div>
    </el-drawer>
    <div style="margin-left: 20px">
      <span style="margin-right: 10px">请选择常用达人收藏夹</span>
      <el-select
        style="width: 350px"
        v-model="collectionId"
        @change="changeCol"
        placeholder="请选择收藏夹"
      >
        <el-option
          v-for="item in colList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <collect-detail
      v-show="collectionId"
      :collectionId="collectionId"
      @addAllToActivity="addAllToActivity"
      @addToActivity="addToActivity"
      :additionalKol="true"
    />
    <div v-show="collectionId || !additional" style="text-align: center">
      <el-button @click="prevStep">
        {{ additional ? "取消" : "上一步" }}
      </el-button>
      <el-button @click="submitKol" type="primary">
        {{ additional ? "确定" : "下一步" }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { getUserCollect, addAllKol } from "@/api/collection";
import collectDetail from "../CollectDetail";
import { mapGetters, mapMutations } from "vuex";
import { addMoreKolListToCampaign } from "@/api/campaign";

export default {
  name: "step2",
  data() {
    return {
      collectionId: "",
      colList: [{ label: "", value: "" }],
      page: 1,
      size: 200,
      isShowBtn: false,
      isShowBoard: false,
      drawerPlatform: "all",
      activityKolList: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
    };
  },
  computed: {
    ...mapGetters(["siteValue"]),
    showActivityKolList() {
      return this.activityKolList[this.drawerPlatform];
    },
    activityKolNumber() {
      return this.activityKolList.all.length;
    },
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    additional: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getUserCollect();
  },
  beforeRouteLeave(to, from, next) {
    this.setIsAddClass(false);
    next();
  },
  methods: {
    ...mapMutations({
      setIsAddClass: "SET_ISADDCLASS",
    }),
    async getUserCollect() {
      // TODO:滚动加载
      let params = {
        search: "",
        page: this.page,
        size: this.size,
        sortColumn: "create_time",
        sortDirection: "desc",
      };
      let { data } = await getUserCollect(params);
      this.colList = data.table.map((v) => ({
        value: v.id,
        label: v.name,
      }));
    },
    showSideBoard() {
      this.isShowBoard = true;
    },
    changeCol(id) {
      this.isShowBtn = true;
      // 以便添加kol时面包屑的跳转
      const res = this.colList.find((item) => item.value === id);
      this.$storage.setItem("activeName", res.label);
      // this.$storage.setItem("activeNameCol", res.label);
    },
    async addAllToActivity() {
      let { code, data } = await addAllKol({
        collectionId: this.collectionId,
      });
      if (code === 0) {
        this.clearAll();
        this.formatToCampaignKol(data);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    addToActivity(checkedKolList) {
      const res = checkedKolList.map((v) => ({
        logoUrl: v.logoUrl,
        site: v.platform,
        kolName: v.name,
        fans: v.kolFans || "N/A",
        id: v.id,
        coopType: "",
        rightsType: "",
        contentType: "",
        otherRights: "",
        comments: "",
        mKolid: v.mKolid,
        kolCategory: v.kolCategory,
      }));
      this.formatToCampaignKol(res);
    },
    formatToCampaignKol(arr) {
      arr.forEach((v) => {
        switch (v.site) {
          case "wechat":
            this.activityKolList.wechat.push(v);
            break;
          case "weibo":
            this.activityKolList.weibo.push(v);
            break;
          case "douyin":
            this.activityKolList.douyin.push(v);
            break;
          case "xiaohongshu":
            this.activityKolList.xiaohongshu.push(v);
            break;
          case "bilibili":
            this.activityKolList.bilibili.push(v);
            break;
          case "kuaishou":
            this.activityKolList.kuaishou.push(v);
            break;
        }
      });
      for (let key in this.activityKolList) {
        if (this.activityKolList[key].length !== 0) {
          let res = new Map();
          this.activityKolList[key] = this.activityKolList[key].filter(
            (a) => !res.has(a.mKolid) && res.set(a.mKolid, 1)
          );
        }
      }
      this.activityKolList.all = [
        ...this.activityKolList.wechat,
        ...this.activityKolList.weibo,
        ...this.activityKolList.douyin,
        ...this.activityKolList.xiaohongshu,
        ...this.activityKolList.bilibili,
        ...this.activityKolList.kuaishou,
      ];
      this.isShowBoard = true;
    },
    delKolFromActivityList(kol) {
      // 要从all和对应平台和all里都删掉
      this.activityKolList.all.splice(
        this.activityKolList.all.findIndex((v) => v.id === kol.id),
        1
      );
      this.activityKolList[kol.site].splice(
        this.activityKolList[kol.site].findIndex((v) => v.id === kol.id),
        1
      );
    },
    clearAll() {
      this.activityKolList = {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      };
    },
    async submitKol() {
      if (this.additional) {
        if (this.activityKolNumber === 0) {
          this.$message({
            type: "warning",
            message: "追加kol不能为0",
          });
        } else {
          let num = this.$storage.getItem("campaignKolNum");
          if (num + this.asideToCompaignKol > 1000) {
            this.$message.error("单个活动KOL数量不能超过1000!");
          } else {
            let { code } = await addMoreKolListToCampaign({
              campaignId: this.id,
              kolList: this.activityKolList.all,
              type: 1,
            });
            if (code === 0) {
              this.$message({
                type: "success",
                message: "追加kol成功",
              });
              this.$router.go(-1);
            } else if (code === 206) {
              this.$message({
                type: "info",
                message: "追加的KOL已经存在，请重新选择",
              });
            }
          }
        }
      } else {
        this.$emit("nextStep", {
          campaignKolList:
            this.activityKolNumber === 0 ? null : this.activityKolList.all,
        });
      }
    },
    prevStep() {
      if (this.additional) {
        this.$router.go(-1);
      } else {
        this.$emit("prevStep");
      }
    },
  },
  components: {
    collectDetail,
  },
};
</script>
<style lang="scss">
.svgWrap {
  .el-radio-button {
    .el-radio-button__inner {
      padding: 0;
      border: none;
    }
  }
}
</style>
<style scoped lang="scss">
.haveKOL {
  width: 29px;
  height: calc(100% - 120px);
  position: fixed;
  right: 0;
  top: 90px;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}

.drawer_content {
  height: 100%;
  display: flex;
  flex-direction: column;

  .svgWrap {
    display: flex;
    justify-content: space-around;

    .svgPlatform {
      font-size: 18px;
      cursor: pointer;
    }

    .all {
      fill: skyblue;
    }

    // 颜色顺序 ： 抖音 微博 微信 小红书
    // color: ['#260e1f', '#FF9933', '#04BE02', '#eb414c']
    .wechat {
      fill: #04be02;
    }

    .weibo {
      fill: #ff9933;
    }

    .douyin {
      fill: #260e1f;
    }

    .xiaohongshu {
      fill: #eb414c;
    }

    .bilibili {
      fill: #fb7299;
    }

    .kuaishou {
      fill: #e95629;
    }
  }

  .drawer_main {
    flex: 1;
    margin-top: 20px;
    overflow: auto;

    .mainBox {
      margin-bottom: 20px;
      padding-right: 6px;
      display: flex;
      align-items: center;

      .left {
        border-radius: 50%;
        width: 34px;
        height: 34px;
      }

      .right {
        flex: 1;
        margin-left: 14px;
        overflow: hidden;

        .kolName {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 26px;
          line-height: 26px;
        }

        .imgIcon {
          font-size: 16px;
        }

        .wechat {
          fill: #04be02;
        }

        .weibo {
          fill: #ff9933;
        }

        .douyin {
          fill: #260e1f;
        }

        .xiaohongshu {
          fill: #eb414c;
        }

        .bilibili {
          fill: #fb7299;
        }

        .kuaishou {
          fill: #e95629;
        }
      }

      .del {
        cursor: pointer;
        align-self: flex-start;
        font-size: 16px;

        &:hover {
          color: skyblue;
        }
      }
    }

    .empty {
      font-size: 20px;
      color: #ddd;
      text-align: center;
    }
  }

  .drawer_footer {
    border-top: 1px solid #ccc;
    padding: 10px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
